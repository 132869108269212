import React, { useState, useEffect } from "react";
import "react-responsive-modal/styles.css";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "../directives/sidebar";
import Header from "../directives/header";
import Footer from "../directives/footer";
import toast, { Toaster } from "react-hot-toast";
import Cookies from "js-cookie";


import Togglesidebar from "../directives/togglesidebar";
import { addAbouUsAction, getAboutUsAction } from "../Action/user.action";
import config from "../config/config";

const AboutUs = () => {

  const loginDetails = !Cookies.get("loginSuccessFarfetchAdmin")
  ? []
  : JSON.parse(Cookies.get("loginSuccessFarfetchAdmin"));
const [formData,setFormData] = useState(
 {   imageTagline:"",
  description:"",
    ourValues:"",
    mainImage:""
}


)

const [oldImagePreview,setOldImagePreview] =useState()
const [data,setData]= useState([])

const [imagePreview,setimagePreviews] = useState();
const handleChange = (e) => {
    setFormData({
        ...formData,
        [e.target.name]: e.target.value
    });
};


const handleUpload = (e) => {
    const img = URL.createObjectURL(e.target.files[0]);
    setimagePreviews(img);
    setFormData({
        ...formData,
        [e.target.name]: e.target.files[0]// Assuming you want to store only the first file
    });
    setOldImagePreview(null)
};
const handleSubmit =async(e)=>{
  e.preventDefault();
try {
  

  let res = await addAbouUsAction(formData);
  if(res.status==200){
    toast.success(res?.data?.msg)
  }else{
    toast.error("Internal server error")
  }
} catch (error) {
  
}
}

const fetchData = async()=>{
  try {
    
 
    let res = await getAboutUsAction()
   setData(res?.data)
   if(res.data){
   formData.imageTagline=res.data[0]?.imageTagline;
   formData.description=res.data[0]?.description;
   formData.ourValues=res.data[0]?.ourValues
   formData.mainImage=res.data[0]?.mainImage
   setOldImagePreview(res.data[0]?.mainImage)
   }
  } catch (error) {
    
  }
}


useEffect(()=>{
  if(loginDetails?.authToken){

    fetchData()
  }
},[])



  return (
    <>
      <div className="page">
        <div id="websidebar" className="">
          <Sidebar />
        </div>

        <div id="mobilesidebar" className="">
          <Togglesidebar />
        </div>
        <Header />
        <Toaster />
        <div className="content">
          <div className="main-content">
            <div className="block justify-between page-header md:flex"></div>
            <div className="grid grid-cols-12 gap-x-6">
              <div className="col-span-12">
                <div className="box">
                  <div className="box-header">
                    <h5 className="box-title">Add About Us Details</h5>
                  </div>
                  <div className="box-body">
                    <form onSubmit={handleSubmit}>
                      <div className="space-y-4">

                      <div className="space-y-2">
                            <label className="ti-form-label mb-0">
                              Image Tagline
                            </label>
                            <input
                              type="Text"
                              className="my-auto ti-form-input"
                              name="imageTagline"
                              placeholder="Image tagline"
                              defaultValue={data ?data[0]?.imageTagline :""}
                              onChange={(e)=>{handleChange(e)}}
                              required={true}
                            />
                            
                          </div>

                        <div className="space-y-2">
                          <label className="ti-form-label mb-2">
                            Description
                          </label>
                          <textarea
                            className="my-auto ti-form-input"
                            placeholder="description"
                            name="description"
                            defaultValue={data ? data[0]?.description:""}
                            onChange={(e)=>{handleChange(e)}}
                            required={true}
                          ></textarea>
                        </div>


                        <div className="space-y-2">
                          <label className="ti-form-label mb-2">
                            Our Values
                          </label>
                          <textarea
                            className="my-auto ti-form-input"
                            placeholder="Our Values"
                            name="ourValues"
                            defaultValue={data?data[0]?.ourValues:""}
                            onChange={(e)=>{handleChange(e)}}
                            required={true}
                          ></textarea>
                        </div>

                        <div className="space-y-2">
                          <label className="ti-form-label mb-2">
                            Main Image
                          </label>
                          <input
                            accept="image/x-png,image/gif,image/jpeg,image/webp"
                            type="file"
                            name="mainImage"
                            // value={data[0]?.mainImage}
                            className="my-auto ti-form-input"
                            onChange={(e)=>{handleUpload(e)}}
                          />
   {oldImagePreview && (
                            <img
                                src={`${config.imageUrl + oldImagePreview}`}
                                alt="Image Preview"
                                style={{ marginTop: '10px', maxWidth: '100%', maxHeight: '150px' }}
                            />
                        )}

                          {imagePreview && (
                          <>
                            <img
                              src={imagePreview}
                              alt="Image Preview"
                              style={{
                                maxWidth: "100px",
                                maxHeight: "100px",
                                marginTop: "10px",
                              }}
                            />
                          </>
                          )}
                         
                        </div>
                      </div>

                      <button
                        type="submit"
                        className="ti-btn ti-btn-primary mt-3"
                      >
                        Submit
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default AboutUs;
